<template>
  <b-menu
    :accordion="false"
    :activable="false"
  >
    <b-menu-list
      :label="label"
    >
      <template
        v-if="!label"
        #label
      >
        <slot name="label" />
      </template>
      <template
        v-if="tree.length"
        #default
      >  
        <TreeItem
          v-for="(node, nodeIndex) in tree"
          :key="nodeIndex"
          :node="node"
          :item-dropdown="itemDropdown"
          :show-leaf-node-options="showLeafNodeOptions"
          :show-parent-node-options="showParentNodeOptions"
        />
      </template>
    </b-menu-list>
    <span v-if="!tree.length">No variables found.</span>
  </b-menu>
</template>

<script >
import { computed } from '@vue/composition-api';
import TreeItem from './TreeItem.vue';
import { menuTreeEventBus, ON_ITEM_SELECT } from './variableTreeEventBus';
const __sfc_main = {};
__sfc_main.props = {
  items: {
    type: Array,
    default: () => [],
    description: 'Items array (expected array of objects)'
    /**
     * Expected shape of each object:
     * { 
     *     name: string,  (Example formats: 'Form > Section > Email', 'Form > Name' etc.)
     *     reference: string, (id for identifying variable)
     *     hasOptions?: boolean, (If set to true, respective node will have dropdown options as per itemDropdown prop),
     *     data?: any,
     *     [key: string]: any
     * }
    **/
  },
  label: {
    type: String,
    default: '',
    description: 'Label for list'
  },
  itemDropdown: {
    type: Object,
    default: null,
    description: 'Component for rendering dropdown options of a variable item in tree'
  },
  showParentNodeOptions: {
    type: Boolean,
    default: false,
    description: 'If set to true, options dropdown will be rendered in parent nodes (collapsable headers)'
  },
  showLeafNodeOptions: {
    type: Boolean,
    default: true,
    description: 'If set to true, options dropdown will be rendered in leaf nodes'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  // subscribe to events from recursively nested child components
  menuTreeEventBus.subscribe(ON_ITEM_SELECT, payload => {
    emit('node-selected', payload);
  });

  //-- tree generation logic --//
  const tree = computed(() => props.items && props.items.length ? generateTree() : []);
  const generateTree = () => {
    // compute items
    let items = props.items.map(item => {
      const {
        name,
        hasOptions,
        ...restItem
      } = item;
      return {
        ...restItem,
        name: name.split(' > ').map(i => i.trim()).join(' > '),
        hasOptions: !!hasOptions
      };
    });
    const itemNames = items.map(item => item.name);
    const itemNameJaggedArray = itemNames.map(itemName => itemName.split('>').map(name => name.trim())); // array of arrays

    /* 
      * Identify all nodes in tree and store them as jagged array (array of arrays) with first array consisting nodes in first level of tree and so on....
      * Eg: End result will look something like this- [[A, B], [X, Y, Z], [H, J]]  (@NOTE: this is a simplified representation of nodes)
      * Here A,B are root nodes X,Y,Z are nodes on 2nd level and H,J are leaf nodes 
    */
    const treeDepth = Math.max(...itemNameJaggedArray.map(items => items.length));
    const treeNodes = []; // array of arrays
    for (let i = 0; i < treeDepth; i++) {
      const nodes = itemNameJaggedArray.filter(items => items.length > i).map(items => items.slice(0, i + 1).join(' > '));
      const leafNodes = nodes.filter(nodeName => itemNames.includes(nodeName)).map(nodeName => {
        const matchedItem = items.find(item => item.name === nodeName);
        if (matchedItem) {
          items = items.filter(item => !(item.name === matchedItem.name && item.reference === matchedItem.reference));
        }
        // structure of leaf nodes
        return {
          ...(matchedItem || {}),
          label: nodeName.split(' > ').slice(-1)[0],
          connectionPath: nodeName,
          hasOptions: props.showLeafNodeOptions && matchedItem?.hasOptions
        };
      });
      const parentNodes = [...new Set(nodes.filter(nodeName => !itemNames.includes(nodeName)))].map(nodeName => ({
        // structure of parent nodes
        label: nodeName.split(' > ').slice(-1)[0],
        connectionPath: nodeName,
        children: [],
        hasOptions: props.showParentNodeOptions
      }));
      treeNodes.push([...parentNodes, ...leafNodes]);
    }

    // connect all nodes in tree as per their relations
    let tree = treeNodes[0];
    treeNodes.forEach((nodes, index) => {
      nodes.forEach(node => {
        if (node.children) {
          const childNodes = treeNodes[index + 1]?.filter(childNode => {
            const nodeNames = childNode.connectionPath.split(' > ');
            const parentPath = nodeNames.slice(0, nodeNames.length - 1).join(' > ');
            return parentPath === node.connectionPath;
          });
          if (childNodes?.length) {
            node.children = node.children.concat(childNodes);
          }
        }
      });
    });
    return tree;
  };
  return {
    tree
  };
};
__sfc_main.components = Object.assign({
  TreeItem
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
.menu-list {
  display: inline-block;
}
</style>
