// @ts-check
import { createHttpServiceConfig } from '@/services/httpFactories';
import { APPLICATION_RESOURCE_PATH, APPLICATION_SERVICE_ENDPOINT, TRANSLATION_RESOURCE_PATH } from './applicationServiceConstants';
import { moduleServiceConfig } from './moduleRequests';

export const translationServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_ENDPOINT, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     * @param {string} [translationReference]
     */
    getTranslationsResourcePath: (applicationId, moduleId, translationReference = '') => { 
        let translationEndpoint = `${moduleServiceConfig.getModuleResourcePath(applicationId, moduleId)}${TRANSLATION_RESOURCE_PATH}`;
        if (translationReference) {
            translationEndpoint += `/${translationReference}`;
        }
        return translationEndpoint;
    }
};

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {import('@/services/application-service/applicationServiceTypes').IFetchTranslationsParams} [params]
 */
export const fetchTranslationsService = (applicationId, moduleId, params = {}) => translationServiceConfig.http({
    method: 'GET',
    url: translationServiceConfig.getTranslationsResourcePath(applicationId, moduleId),
    params
});
