<template>
  <div>
    <ScreenOptions 
      v-model="formBuilderInfo"
    />
    <b-button
      type="is-primary"
      expanded
      @click="isFormBuilderModalActive = true;"
    >
      <b-icon
        class="mr-1"
        icon="pencil-box-multiple-outline"
      />
      Edit Form
    </b-button>
    <FormBuilderModal 
      v-model="isFormBuilderModalActive"
    />
  </div>
</template>
    
<script >
// libs
import { ref, onMounted, watch } from '@vue/composition-api';

// components
import FormBuilderModal from '@/modules/builder/components/form-builder/FormBuilderModal.vue';
import ScreenOptions from '@/modules/builder/components/module-sidebar/screen/shared/ScreenOptions.vue';

// services
import { fetchLayoutService } from '@/services/application-service/layoutRequests';

// composables
import { useRoute } from '@/hooks/vueRouter';
import { useFormBuilder } from '@/modules/builder/components/form-builder/formBuilder';
import { useVariableManager } from '@/modules/builder/components/module-sidebar/screen/shared/screenVariables';

// others
import { createSection, createPasswordInput, createNextButton } from '@/modules/builder/components/form-builder/form-elements/elementFactories';
import { generateDefaultForm } from '@/modules/builder/components/module-sidebar/screen/shared/screenUtils';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  //-- use composables --//
  const route = useRoute();
  const {
    formBuilderInfo
  } = useFormBuilder();
  const variableManager = useVariableManager();

  //-- layouts logic --//
  const layouts = ref([]);
  onMounted(async () => {
    const {
      data: {
        data
      }
    } = await fetchLayoutService(route.params.appId);
    layouts.value = data;
  });

  //-- form builder modal --//
  const isFormBuilderModalActive = ref(false);
  const defaultValue = {
    ...generateDefaultForm(),
    name: 'Reset Password',
    custom_url_name: 'reset-password',
    sections: [createSection([createPasswordInput(), createPasswordInput('Re-Password'), createNextButton()])],
    additionalInfo: {
      database: '',
      table: '',
      idColumnType: 'text-input',
      idColumn: '',
      passwordColumn: ''
    }
  };
  const loadInfo = () => {
    formBuilderInfo.value = {
      ...defaultValue,
      ...props.value
    };
  };
  loadInfo();
  watch(() => formBuilderInfo.value, () => {
    emit('input', formBuilderInfo.value);
    variableManager.saveVariables();
  }, {
    deep: true
  });
  return {
    formBuilderInfo,
    isFormBuilderModalActive
  };
};
__sfc_main.components = Object.assign({
  ScreenOptions,
  FormBuilderModal
}, __sfc_main.components);
export default __sfc_main;
</script>
  <style lang="scss">
  @import '~@/style/utilities.scss';
  </style>
    