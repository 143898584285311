<template>
  <div class="popup-header">
    <label class="menu-label">{{ label }}</label>
    <b-select
      v-model="selectedVariableType"
      size="is-small"
      class="popup-header-dropdown"
      @input="notifyFilterUpdate()"
    >
      <option
        aria-role="listitem"
        :value="variableTypes.VARIABLE"
      >
        Variables
      </option>
      <option
        aria-role="listitem"
        :value="variableTypes.TRANSLATION_KEY"
      >
        Translation Keys
      </option>
    </b-select>
  </div>
</template>

<script >
import { variableTypes, useVariableTreePopup } from './variableTreePopup';
const __sfc_main = {};
__sfc_main.props = {
  label: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    selectedVariableType
  } = useVariableTreePopup();
  const notifyFilterUpdate = () => {
    emit('filter-variables');
  };
  return {
    variableTypes,
    selectedVariableType,
    notifyFilterUpdate
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .menu-label {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
}
</style>
