<template>
  <div>
    <ModuleTable 
      :icon-mapper="iconMapper"
      :modules="modules"
      :loading="areModulesLoading"
      @delegate-action="handleTableAction"
    />
  </div>
</template>

<script >
// lib
import { onMounted, ref } from '@vue/composition-api';
// components
import ModuleTable from '@/modules/builder/components/shared/module-list-blocks/ModuleTable.vue';
// stores
import { useApplicationStore } from '@/modules/builder/store/applicationStore';
import { useFormBuilderStore } from '@/modules/builder/store/formBuilderStore';
// services
import { fetchModulesService } from '@/services/application-service/moduleRequests';
// composables
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useModuleTable } from '@/modules/builder/components/shared/module-list-blocks/moduleTable';
// others
import { API_TYPE_ID, SCHEDULED_JOB_TYPE_ID, WEB_APP_TYPE_ID } from '@/constants/moduleTypes';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const router = useRouter();
  const route = useRoute();
  const moduleTable = useModuleTable();
  const applicationStore = useApplicationStore();
  const formBuilderStore = useFormBuilderStore();
  const {
    tableActions
  } = useModuleTable();
  const modules = ref([]);
  const areModulesLoading = ref(false);
  const iconMapper = module => {
    if (module.type === 'Web app/Screens' && module.guarded_module_id) {
      return 'lock';
    } else if (module.type === 'Web app/Auth-Sign In') {
      return 'shield-key';
    } else if (module.type === 'Web app/Auth-Account Recovery') {
      return 'account-reactivate';
    } else if (module.type === 'Web app/Auth-Reset Password') {
      return 'lock-reset';
    } else if (module.type === 'Web app/Screens' && !module.guarded_module_id) {
      return 'lock-open-variant';
    } else {
      return '';
    }
  };
  const handleTableAction = payload => {
    const {
      tableAction,
      selectedRow
    } = payload;
    const {
      EDIT,
      CREATE,
      DUPLICATE,
      DELETE,
      OPEN_VERSIONS,
      OPEN_ANALYTICS
    } = tableActions.value;
    const actionHandlers = {
      [EDIT]: openModuleBuilder,
      [CREATE]: handleCreatingModule,
      [DUPLICATE]: fetchModules,
      [DELETE]: fetchModules,
      [OPEN_VERSIONS]: openVersions,
      [OPEN_ANALYTICS]: openAnalytics
    };
    const handler = actionHandlers[tableAction];
    if (handler) {
      handler(selectedRow);
    }
  };
  const openModuleBuilder = tableRow => {
    const {
      id
    } = tableRow;
    applicationStore.reset();
    formBuilderStore.reset();
    router.push(`/application/${route.params.appId}/module/${id}/edit`);
  };
  const openVersions = selectedModule => {
    router.push('/application/' + route.params.appId + '/module/' + selectedModule.id + '/versions');
  };
  const openAnalytics = () => {
    router.push('/analytics');
  };
  const handleCreatingModule = () => {
    emit('create');
  };
  const fetchModules = async () => {
    try {
      areModulesLoading.value = true;
      let {
        data: {
          data
        }
      } = await fetchModulesService(route.params.appId, {
        typeIds: [API_TYPE_ID, SCHEDULED_JOB_TYPE_ID, WEB_APP_TYPE_ID]
      });
      modules.value = moduleTable.transformTableData(data);
    } catch (err) {
      console.error(err);
    } finally {
      areModulesLoading.value = false;
    }
  };
  onMounted(() => {
    fetchModules();
  });
  return {
    modules,
    areModulesLoading,
    iconMapper,
    handleTableAction
  };
};
__sfc_main.components = Object.assign({
  ModuleTable
}, __sfc_main.components);
export default __sfc_main;
</script>
