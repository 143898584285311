// builder stores ids
export const DATABASE_STORE_ID = 'database';
export const MODULE_STORE_ID = 'module';
export const APPLICATION_STORE_ID = 'application';
export const APPLICATION_NODES_STORE_ID = 'applicationNodes';
export const FORM_BUILDER_STORE_ID = 'formBuilder';
export const MODULE_DEPLOY_STORE_ID = 'moduleDeploy';
export const MODULE_GRAPH_STORE_ID = 'moduleGraph';
export const FORMULA_STORE_ID = 'formula';
export const WORKFLOW_STORE_ID = 'workflow';
export const LOCALIZATION_STORE_ID = 'localization';

// core stores ids
export const CORE_APPLICATION_STORE_ID = 'coreApplication';
export const APPLICATION_META_STORE_ID = 'applicationMeta';

// user stores ids
export const USER_STORE_ID = 'user';

