// @ts-check
import { computed, reactive, ref, set } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { transformStringToHtml } from '@/helpers/util';
import { generateNode } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';
import { ACTION_NODE_TYPE, INTERGRATION_NODE_TYPE, SCREEN_NODE_TYPE } from '@/modules/builder/constants/moduleGraphNodes';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { MODULE_GRAPH_STORE_ID } from '@/constants/storeIds';
import { addModuleNodeService, deleteModuleNodeService, fetchModuleNodeService, updateModuleNodeService } from '@/services/application-service/moduleNodesResquests';
import {createNodeTagService} from '@/services/version-control-service/versionControlNodeRequests';
import {fetchModuleTagsService} from '@/services/version-control-service/versionControlModuleRequests';

//-- common utils --//
/**
 * @param {import('./types/moduleGraph').INode[]} nodes
 * @param {import('./types/moduleGraph').NodeTypes} nodeType
 */
const filterNodesByType = (nodes, nodeType) => nodes.filter(node => node.type === nodeType);

//-- sub-stores --//
const useNodesSubStore = () => {
    /** @type {import('@vue/composition-api').Ref<import('./types/moduleGraph').INode[]>} */
    const nodes = ref([]);
    
    /**
     * @param {string} dNodeId 
     */
    const deleteNode = (dNodeId) => {
        const nodeIndex = nodes.value.findIndex(node => node.nodeId === dNodeId);
        if (nodeIndex) {
            nodes.value.splice(nodeIndex, 1);
        }
    };
    const addNode = (newNodeData, parentNode = null) => {
        const newNode = generateNode(nodes.value, newNodeData, parentNode);
        nodes.value.push(newNode);
    };
    /**
     * @param {string} nodeId
     */
    const getNodeById = (nodeId) => nodes.value.find(node => node.nodeId === nodeId);
    /**
     * @param {string} connectionId
     */
    const getNodeByConnectionId = (connectionId) => nodes.value.find(node => 
        node.connections.some(connection => connection.id === connectionId)
    );
    /**
     * @param {string} childNodeId 
     */
    const getParentNode = (childNodeId) => {
        const childNode = getNodeById(childNodeId);
        if (childNode) {
            const parentNodeId = childNode.position.split('/').slice(-1)[0];
            return getNodeById(parentNodeId);
        }
        return null;
    };
    
    /** @type {import('@vue/composition-api').Ref<import('./types/moduleGraph').INode>} */
    const selectedNode = ref(null);
    const selectedNodeId = computed(() => selectedNode.value ? selectedNode.value.nodeId : '');
    const selectedNodeType = computed(() => selectedNode.value ? selectedNode.value.type : '');
    /**
     * @param {string | null} nodeId
     */
    const selectNode = (nodeId) => {
        selectedNode.value = nodeId ? nodes.value.find(node => node.nodeId === nodeId) : null;
    };
    /**
     * @param {object} newNodeData 
     */
    const updateSelectedNodeData = (newNodeData) => {
        selectedNode.value.data = newNodeData;
    };

    //-- node info logic --//
    const nodeInfo = ref({});
    const setNodeInfo = (newNodeInfo, nodeId) => {
        if (newNodeInfo) {
            if (nodeInfo.value[nodeId]) {
                nodeInfo.value[nodeId] = newNodeInfo;
            } else {
                set(nodeInfo.value, nodeId, newNodeInfo);
            }
        }
    };
    const getNodeInfoById = async (appId, moduleId, nodeId) => {
        if (!nodeInfo.value[nodeId] || Object.keys(nodeInfo.value[nodeId]).length === 0) {
            await fetchNodeInfo(appId, moduleId, nodeId);
        }
        return nodeInfo.value[nodeId];
    };
    const fetchNodeInfo = async (appId, moduleId, nodeId) => {
        try {
            const { data: { data: { data } } } = await fetchModuleNodeService(appId, moduleId, nodeId);
            if(data?.type === 'screen'){
                if(data.sections){
                    for(let section of data.sections){
                        if(section.fields.length && section.fields[0].length){
                            for(let division of section.fields){
                                for(let field of division){
                                    if(['next-btn','back-btn'].includes(field.type) && field.condition === undefined){
                                        field.condition = {
                                            logicalOperator: 'all',
                                            children: [],
                                        };
                                        field.show = false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            setNodeInfo(data, nodeId);
        } catch (err) {
            console.error(err);
        }
    };
    const addNodeInfo = async (appId, moduleId, nodeId, newNodeInfo) => {
        try {
            const { data: { data: { data } } } = await addModuleNodeService(appId, moduleId, nodeId, newNodeInfo);
            setNodeInfo(data, nodeId);

            const response = await fetchModuleTagsService(moduleId, {module_update: true});
            await createNodeTagService(moduleId, nodeId, response.data.data[0].id, newNodeInfo);

        } catch (err) {
            console.error(err);
        }
    };
    const updateNodeInfo = async (appId, moduleId, nodeId, updatedNodeInfo) => {
        try {
            const { data: { data: { data } } } = await updateModuleNodeService(appId, moduleId, nodeId, updatedNodeInfo);
            setNodeInfo(data, nodeId);

            // fetch tag for put update node info
            const response = await fetchModuleTagsService(moduleId, {module_update: true});
            /**
             * if tag is not available then create a new tag POST request
             */
            await createNodeTagService(moduleId, nodeId, response.data.data[0].id, updatedNodeInfo);
        } catch (err) {
            console.error(err);
        }
    };
    const deleteNodeInfo = async (appId, moduleId, nodeId) => {
        try {
            await deleteModuleNodeService(appId, moduleId, nodeId);
            if (nodeInfo.value[nodeId]) {
                delete nodeInfo.value[nodeId];
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        nodes,
        addNode,
        deleteNode,
        getNodeById,
        getNodeByConnectionId,
        getParentNode,
        selectedNode,
        selectNode,
        selectedNodeId,
        selectedNodeType,
        updateSelectedNodeData,
        // node info
        nodeInfo,
        setNodeInfo,
        getNodeInfoById,
        fetchNodeInfo,
        addNodeInfo,
        updateNodeInfo,
        deleteNodeInfo
    };
};

const useConnectionSubStore = (deps) => {
    const { nodesSubStore } = deps;
    const connectionMode = ref(false);
    const moduleStore = useModuleStore();

    /** @type {import('./types/moduleGraph').INewConnection} */
    const newConnection = reactive({
        sourceNode: null,
        targetNode: null
    });

    const defaultCondition = ref({
        label: 'always',
        query: {}
    });

    const connections = computed(() => nodesSubStore.nodes.value.flatMap(node => node.connections));

    //-- select connection logic --//
    /** @type {import('@vue/composition-api').Ref<import('./types/moduleGraph').IConnection | null>} */
    const selectedConnection = ref(null);
    const selectedConnectionId = computed(() => selectedConnection.value ? selectedConnection.value.id : '');
    /**
     * @param {string | null} connectionId
     */
    const selectConnection = (connectionId) => {
        selectedConnection.value = connectionId ? connections.value.find(connection => connection.id === connectionId) : null;
    };

    //-- connection label logic --//
    const symbolMappings = [
        {
            name: 'equals',
            symbol: '='
        },
        {
            name: 'does not equal',
            symbol: '!='
        },
        {
            name: 'less than',
            symbol: '<'
        },
        {
            name: 'more than',
            symbol: '>'
        },
        {
            name: 'less than or equal to',
            symbol: '<='
        },
        {
            name: 'more than or equal to',
            symbol: '>='
        },
        {
            name: 'empty',
            symbol: '= Ø'
        },
        {
            name: 'not empty',
            symbol: '!= Ø'
        },
        {
            name: 'contains',
            symbol: '⊆'
        },
        {
            name: 'does not contain',
            symbol: '⊄'
        },
        {
            name: 'begins with',
            symbol: '^'
        },
        {
            name: 'ends with',
            symbol: '$'
        },
        {
            name: 'is part of',
            symbol: '∈'
        },
        {
            name: 'is not part of',
            symbol: '∉'
        }
    ];
    /**
     * @param {string} operatorName 
     */
    const getOperatorSymbol = (operatorName) => {
        const symbolMapping = symbolMappings.find(sMapping => sMapping.name === operatorName);
        return symbolMapping ? symbolMapping.symbol : '';
    };
    /**
     * @param {string} htmlText 
     */
    const getTextValue = (htmlText) => {
        const valueHtml = transformStringToHtml(htmlText);
        return valueHtml ? valueHtml.innerText : '';
    };

    const updateConditionQueryOperand = (connections) => {
        // update the condition query operand
        connections.map (connection => {
            connection.condition.query?.children?.map( qChild => {
                const variable = moduleStore.moduleVariables.find(v => v.reference === qChild.query.rule);
                if (variable) {
                    qChild.query.operand = variable.name;
                } else {
                    const envVar = moduleStore.environmentVariables.find(v => v.reference === qChild.query.rule);
                    if (envVar) {
                        qChild.query.operand = envVar.name;
                    }
                }
            });
        });
    };

    /**
     * @param {import('./types/moduleGraph').IConnection[]} connections
     */
    const updateConnectionsLabel = (connections) => {
        updateConditionQueryOperand(connections);
        const haveCondition = doesAnyConnectionHaveCondition(connections);
        connections.forEach(connection => {
            const conditions = connection.condition.query?.children || [];
            if (haveCondition) {
                const hasAlwaysCondition = conditions.some(qChild => qChild.query.operand === 'Always go through');
                if (hasAlwaysCondition) {
                    connection.condition.label = 'always';
                } else if(conditions.length) {
                    const label = connection.condition.query.children.map(qChild => 
                        // @ts-ignore-next-line
                        `${qChild.query.operand.split('>').slice(-1)[0].trim()} ${getOperatorSymbol(qChild.query.operator)} ${getTextValue(qChild.query.value)}`.trim()
                    ).join(connection?.condition?.query?.logicalOperator === 'any' ? ' OR '  : ' & ');
                    connection.condition.label = label;
                } else {
                    connection.condition.label = 'else';
                }
            } else {
                connection.condition.label = 'always';
            }
        });
    };
    /**
     * @param {import('./types/moduleGraph').IConnection[]} connections
     */
    const doesAnyConnectionHaveCondition = (connections) => {
        return connections.some(connection => {
            return connection.condition.query?.children?.length && !connection.condition.query.children.some(qChild => qChild.query.operand === 'Always go through');
        });
    };

    return {
        connections,
        connectionMode,
        newConnection,
        selectedConnection,
        selectedConnectionId,
        defaultCondition,
        selectConnection,
        updateConnectionsLabel,
        doesAnyConnectionHaveCondition
    };
};

//-- main store --//
export const useModuleGraphStore = defineStore(MODULE_GRAPH_STORE_ID, () => {
    //-- compose other stores --//
    const moduleStore = useModuleStore();

    //-- compose sub-stores --//
    const nodesSubStore = useNodesSubStore();
    const connectionSubStore = useConnectionSubStore({
        nodesSubStore
    });

    const isAuthScreen = computed( () => ['Sign In', 'Account Recovery', 'Reset Password'].includes(nodesSubStore.selectedNode.value?.data?.type));
    const authScreenType = computed(() => isAuthScreen.value ? nodesSubStore.selectedNode.value?.data?.type : null);

    const integrationNodes = computed(() => filterNodesByType(nodesSubStore.nodes.value, INTERGRATION_NODE_TYPE));
    const screenNodes = computed(() => filterNodesByType(nodesSubStore.nodes.value, SCREEN_NODE_TYPE));
    const authScreenNodes = computed(() => screenNodes.value.filter(node => node.data.type === 'Sign In'));
    const actionNodes = computed(() => filterNodesByType(nodesSubStore.nodes.value, ACTION_NODE_TYPE));

    const actionDocuments = computed(() => actionNodes.value.filter(action => action.data.type === 'Create Document' || action.data.type === 'Merge Documents').map(action => ({
        ...action.data,
        nodeId: action.nodeId
    })));

    const screenFiles = computed(() => {
        /**
         * @type {import('./types/moduleGraph').IGetFileField[]}
         */
        const fields = [];
        screenNodes.value.forEach(/** @type {import('./types/moduleGraph').IScreenNode} */ screen => {
            const screenData = screen.data;
            if (screenData.info) {
                // @ts-ignore
                screenData.info.sections.forEach(section => {
                    section.fields.forEach(column => {
                        column.forEach(field => {
                            if (field.type === 'file-upload') {
                                fields.push({
                                    nodeId: field.id,
                                    // @ts-ignore
                                    name: screen.name,
                                    info: {
                                        fileName: field.properties.basic.label,
                                        extension: ''
                                    }
                                });
                            }
                        });
                    });
                });
            }
        });
        return fields;
    });

    /**
     * @param {import('./types/moduleGraph').IUpdateVariablePayload} payload
     */
    const updateVariableReferencesInAllScreens = (payload) => {
        const newVariable = `Form > ${payload.newVariable || 'null'}`;
        const newVariableText = `@Form &gt; ${payload.newVariable || 'null'}`;
        screenNodes.value.forEach(screen => {
            const screenData = screen.data;
            // @ts-ignore
            screenData.info.sections.forEach(section => {
                section.fields.forEach(fields => {
                    fields.forEach(field => {
                        if (field.type === 'text' || field.type === 'toc') {
                            // convert string to html element
                            const htmlDoc = transformStringToHtml(field.properties.basic.rawText);
                            // perform DOM manipulations
                            const variableMentions = htmlDoc.querySelectorAll(`[data-mention-id="${payload.mentionId}"]`);
                            variableMentions.forEach(mentionElement => {
                                mentionElement.innerHTML = newVariableText;
                                mentionElement.setAttribute('name', newVariable);
                            });
                            // assign back updated html string
                            field.properties.basic.rawText = htmlDoc.innerHTML;
                        }
                    });
                });
            });
        });
    };

    /**
     * @param {string[]} mentionIds 
     */
    const deleteVariableReferencesFromAllScreens = (mentionIds) => {
        screenNodes.value.forEach(screen => {
            const screenData = screen.data;
            // @ts-ignore
            screenData.info?.sections.forEach(section => {
                section.fields.forEach(fields => {
                    fields.forEach(field => {
                        if (field.type === 'text' || field.type === 'toc') {
                            // convert string to html element
                            const htmlDoc = transformStringToHtml(field.properties.basic.rawText);
                            // perform DOM manipulations
                            mentionIds.forEach(mentionId => {
                                const variableMentions = htmlDoc.querySelectorAll(`[data-mention-id="${mentionId}"]`);
                                variableMentions.forEach(mentionElement => {
                                    mentionElement.remove();
                                });
                            });
                            // assign back updated html string
                            field.properties.basic.rawText = htmlDoc.innerHTML;
                        }
                    });
                });
            });
        });
    };

    /**
     * Selects an entity in graph
     * @param {import('./types/moduleGraph').SelectTypeParam} type
     * @param {string} selectedId
     */
    const select = (type, selectedId) => {
        if (type === 'node') {
            nodesSubStore.selectNode(selectedId);
            connectionSubStore.selectConnection(null);
        } else {
            connectionSubStore.selectConnection(selectedId);
            nodesSubStore.selectNode(null);
        }
    };

    const reset = () => {
        moduleStore.shouldAvoidModuleUpdate = true;
        nodesSubStore.nodes.value = [];
        nodesSubStore.nodeInfo.value = {};
        nodesSubStore.selectedNode.value = null;
        connectionSubStore.connectionMode.value = false;
        connectionSubStore.selectedConnection = null;
        connectionSubStore.newConnection.sourceNode = null;
        connectionSubStore.newConnection.targetNode = null;
    };

    return {
        ...nodesSubStore,
        ...connectionSubStore,
        integrationNodes,
        screenNodes,
        authScreenNodes,
        actionNodes,
        actionDocuments,
        screenFiles,
        updateVariableReferencesInAllScreens,
        deleteVariableReferencesFromAllScreens,
        select,
        reset,
        isAuthScreen,
        authScreenType
    };
});
