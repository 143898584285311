<template>
  <div>
    <BaseSelect
      v-model="sidebarType"
      placeholder="Select a screen"
      label="Screen type"
      @input="setSelectedScreenType($event)"
    >
      <option
        v-for="(screen, index) in screenList"
        :key="index"
        :value="screen.type"
      >
        {{ screen.label }}
      </option>
    </BaseSelect>
    <SidebarLoader v-if="isSidebarLoading" />
    <component
      :is="screenComponentMap[sidebarType]"
      v-else
      v-model="sidebarInfo"
    />
  </div>
</template>

<script >
// libs
import { computed, ref } from '@vue/composition-api';
// components
import ScreenForm from '@/modules/builder/components/module-sidebar/screen/ScreenForm.vue';
import SignInForm from '@/modules/builder/components/module-sidebar/screen/SignInForm.vue';
import AccountRecoveryForm from '@/modules/builder/components/module-sidebar/screen/AccountRecoveryForm.vue';
import ResetPasswordForm from '@/modules/builder/components/module-sidebar/screen/ResetPasswordForm.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
import SidebarLoader from '@/modules/builder/components/module-sidebar/SidebarLoader.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// services
import { addAuthModuleCustomNodeService, removeAuthModuleAuthNodeService } from '@/services/application-service/module-auth/authModuleRequests';
// composables
import { useRoute } from '@/hooks/vueRouter';
import { useModuleSidebar } from '@/modules/builder/components/module-sidebar/moduleSidebar';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  //-- use composables --//
  const moduleStore = useModuleStore();
  const moduleGraphStore = useModuleGraphStore();
  const route = useRoute();
  const {
    isSidebarLoading,
    sidebarType
  } = useModuleSidebar();
  const screenTypes = {
    FORM_BUILDER: 'Form builder',
    SIGN_IN: 'Sign In',
    ACCOUNT_RECOVERY: 'Account Recovery',
    RESET_PASSWORD: 'Reset Password'
  };
  const screenList = ref([{
    type: screenTypes.FORM_BUILDER,
    description: 'Description...',
    icon: 'monitor',
    label: 'Form builder'
  }, {
    type: screenTypes.SIGN_IN,
    description: 'Description...',
    icon: 'shield-key',
    label: 'Auth - Sign In'
  }, {
    type: screenTypes.ACCOUNT_RECOVERY,
    description: 'Description...',
    icon: 'shield-key',
    label: 'Auth - Account Recovery'
  }, {
    type: screenTypes.RESET_PASSWORD,
    description: 'Description...',
    icon: 'shield-key',
    label: 'Auth - Reset Password'
  }]);
  const screenComponentMap = ref({
    [screenTypes.FORM_BUILDER]: ScreenForm,
    [screenTypes.SIGN_IN]: SignInForm,
    [screenTypes.ACCOUNT_RECOVERY]: AccountRecoveryForm,
    [screenTypes.RESET_PASSWORD]: ResetPasswordForm
  });
  const sidebarInfo = computed({
    get() {
      return props.value;
    },
    set(newValue) {
      const payload = {
        ...newValue,
        type: sidebarType.value
      };
      emit('input', payload);
    }
  });
  const setSelectedScreenType = async screenType => {
    const {
      appId
    } = route.params;
    const {
      moduleId
    } = moduleStore;
    const tmpModuleDetails = moduleStore.moduleDetails;
    const {
      auth_nodes
    } = moduleStore.moduleDetails;
    let moduleAuthNode = null;
    if (auth_nodes && auth_nodes.nodes.length) {
      moduleAuthNode = auth_nodes.nodes.filter(n => n.nodeId === moduleGraphStore.selectedNodeId);
    }
    if (['Sign In', 'Account Recovery', 'Reset Password'].includes(screenType)) {
      if (!moduleAuthNode?.length) {
        // push module auth node
        const payload = {
          nodeId: moduleGraphStore.selectedNodeId,
          type: screenType
        };
        const addNode = await addAuthModuleCustomNodeService(appId, moduleId, payload);
        tmpModuleDetails.auth_nodes = addNode.data.data.auth_nodes;
      }
    } else if (screenType === 'Form builder') {
      if (moduleAuthNode?.length) {
        const removeNode = await removeAuthModuleAuthNodeService(appId, moduleId, moduleAuthNode[0]);
        tmpModuleDetails.auth_nodes = removeNode.data.data.auth_nodes;
      }
    }
  };
  return {
    isSidebarLoading,
    sidebarType,
    screenList,
    screenComponentMap,
    sidebarInfo,
    setSelectedScreenType
  };
};
__sfc_main.components = Object.assign({
  BaseSelect,
  SidebarLoader
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
.select.is-empty select {
  color: hsl(0deg, 0%, 29%) !important;
}
</style>
