import { computed, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { capitalize } from 'lodash';
import { LOCALIZATION_STORE_ID } from '@/constants/storeIds';
import { fetchTranslationsService } from '@/services/application-service/localizationRequests';
import { useModuleStore } from '@/modules/builder/store/moduleStore';

export const useLocalizationStore = defineStore(LOCALIZATION_STORE_ID, () => {
    const moduleStore = useModuleStore();
    
    const translations = ref([]);

    const translationKeys = computed(() => {
        let keys = [];
        if (moduleStore.variables?.length && translations.value?.length) {
            keys = translations.value.map(translation => {
                let translationName = '';
                const matchedVariable = moduleStore.variables.find(variable => translation.reference.includes(variable.reference));
                const fieldPart = translation.reference.split('_').at(-1);
                if (matchedVariable && fieldPart) {
                    translationName = `${matchedVariable.name} > ${capitalize(fieldPart)}`; 
                }
                return {
                    ...translation,
                    name: translationName
                };
            });
            keys = keys.filter(key => key.name);
        }
        return keys;
    }); 

    const fetchTranslations = async (appId, moduleId) => {
        try {
            let { data: { data } } = await fetchTranslationsService(appId, moduleId);
            translations.value = data;
        } catch (err) {
            console.error(err);
        }
    };

    return {
        translations,
        translationKeys,
        fetchTranslations
    };
});
