<template>
  <b-dropdown
    class="node-options"
    aria-role="list"
    append-to-body
  >
    <template #trigger="slotProps">
      <b-button
        size="sm"
        icon-right="dots-horizontal"
        class="options-button"
        :class="slotProps.active ? 'visible' : ''"
      />
    </template>
    <b-dropdown-item
      aria-role="listitem"
      @click="copyNode()"
    >
      <b-icon
        size="is-small"
        class="mr-1"
        icon="content-copy"
      />
      <span class="ml-2">Copy</span>
    </b-dropdown-item>
    <b-dropdown-item
      disabled
      aria-role="listitem"
    >
      <b-icon
        size="is-small"
        class="mr-1"
        icon="content-duplicate"
      />
      <span class="ml-2">Clone</span>
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="listitem"
      custom
      class="is-flex is-align-items-center is-justify-content-center"
    >
      <b-button
        icon-left="delete-outline"
        size="is-small"
        type="is-danger is-light"
        class="rounded-8 w-full"
        @click.stop="handleDelete()"
      >
        Delete
      </b-button>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script >
import { removeAuthModuleAuthNodeService } from '@/services/application-service/module-auth/authModuleRequests';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// others
import { moduleEventBus, ON_NODE_REMOVED } from '@/modules/builder/common/moduleEventBus';
import { generateNode } from '@/modules/core/components/generics/base-tree-flow/treeFlowUtils';
import { useRoute } from '@/hooks/vueRouter';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { uuid } from 'vue-uuid';
import { cloneDeep } from 'lodash';
const __sfc_main = {};
__sfc_main.props = {
  nodeId: {
    type: String,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- use composables --//
  const moduleGraphStore = useModuleGraphStore();
  const moduleStore = useModuleStore();
  const route = useRoute();

  //--delete node logic --//
  const handleDelete = async () => {
    try {
      const {
        appId
      } = route.params;
      const {
        moduleId
      } = moduleStore;
      const parentNode = moduleGraphStore.getParentNode(props.nodeId);
      // delete auth node
      const nodeData = moduleGraphStore.getNodeById(props.nodeId);
      if (nodeData.type === 'screen' && ['Sign In', 'Account Recovery', 'Reset Password'].includes(nodeData.data.type)) {
        // delete auth node as well
        const removeNode = await removeAuthModuleAuthNodeService(appId, moduleId, {
          nodeId: props.nodeId,
          type: nodeData.data.type
        });
        moduleStore.moduleDetails.auth_nodes = removeNode.data.data.auth_nodes;
      }
      // delete node info
      await moduleGraphStore.deleteNodeInfo(appId, moduleId, props.nodeId);
      // update connection labels
      if (parentNode) {
        const siblingConnections = parentNode.connections.filter(connection => connection.targetNodeId !== props.nodeId);
        moduleGraphStore.updateConnectionsLabel(siblingConnections);
      }
      // notify subscribers
      moduleEventBus.publish(ON_NODE_REMOVED, {
        id: props.nodeId
      });
      // delete node
      moduleGraphStore.deleteNode(props.nodeId);
    } catch (err) {
      console.error(err);
    }
  };

  //-- copy node logic --//
  const copyNode = async () => {
    const {
      appId
    } = route.params;
    const {
      moduleId
    } = moduleStore;

    // generate node and update in module data
    const sourceNode = moduleGraphStore.getNodeById(props.nodeId);
    const nodeCopy = generateNode(moduleGraphStore.nodes, sourceNode);
    nodeCopy.data.name = `Copy of ${sourceNode.data.name}`;
    moduleGraphStore.nodes.push(nodeCopy);

    // generate node info of copied node and save it
    const sourceNodeInfo = await moduleGraphStore.getNodeInfoById(appId, moduleId, sourceNode.nodeId);
    const {
      replacedVariablesIdMap,
      copiedNodeInfo
    } = generateCopiedNodeInfo(sourceNodeInfo, nodeCopy);
    await moduleGraphStore.addNodeInfo(appId, moduleId, nodeCopy.nodeId, copiedNodeInfo);

    // create new variables for copied node
    let copiedNodeVariables = moduleStore.moduleVariables.filter(variable => variable.node_id === sourceNode.nodeId).map(variable => ({
      ...variable,
      node_id: nodeCopy.nodeId,
      name: variable.name.split(' > ').slice(1).join(' > '),
      reference: variable.reference.replace(sourceNode.nodeId, nodeCopy.nodeId)
    }));
    copiedNodeVariables = copiedNodeVariables.map(variable => ({
      ...variable,
      reference: replacedVariablesIdMap[variable.reference] ? variable.reference.replace(variable.reference, replacedVariablesIdMap[variable.reference]) : variable.reference
    }));
    await createVariables({
      nodeId: nodeCopy.nodeId,
      nodeName: nodeCopy.data.name,
      variables: copiedNodeVariables
    });
  };
  const generateCopiedNodeInfo = (sourceNodeInfo, copiedNode) => {
    /** Map maintaining old variable ID's of source node to respective new variable ID's of the copied node */
    const replacedVariablesIdMap = {};
    const copiedNodeInfo = cloneDeep(sourceNodeInfo);
    copiedNodeInfo.name = copiedNode.data.name;
    if (copiedNode.type === 'screen') {
      // handle creating new field ID's in copied screen node and maintain info of replaced variables accordingly 
      copiedNodeInfo.custom_url_name = copiedNode.data.name;
      copiedNodeInfo.sections.forEach(section => {
        section.fields.forEach(column => {
          column.forEach(field => {
            if (field.id) {
              const newFieldId = uuid.v4();
              if (field.type === 'grid') {
                field.properties.basic.cells.data.forEach((row, rowIndex) => {
                  row.forEach((cell, cellIndex) => {
                    if (cell.properties.basic.element?.id) {
                      const newGridElementId = `${newFieldId}_cell${rowIndex}${cellIndex}`;
                      replacedVariablesIdMap[`${field.id}_cell${rowIndex}${cellIndex}`] = newGridElementId;
                      cell.properties.basic.element.id = newGridElementId;
                    }
                  });
                });
              }
              replacedVariablesIdMap[field.id] = newFieldId;
              field.id = newFieldId;
            }
          });
        });
      });
    } else if (copiedNode.type === 'action' && copiedNode.data.type === 'Map/Pair Data' && copiedNodeInfo.dataType === 'Variables') {
      // handle creating new variable reference for Data mapper variables and maintain info of replaced variables accordingly
      copiedNodeInfo.fields.variables = copiedNodeInfo.fields.variables.map(variable => {
        const newVariableId = uuid.v4();
        replacedVariablesIdMap[variable.reference] = newVariableId;
        variable.reference = newVariableId;
        return variable;
      });
    }
    return {
      replacedVariablesIdMap,
      copiedNodeInfo
    };
  };
  const createVariables = async payload => {
    const {
      appId
    } = route.params;
    const {
      moduleId
    } = moduleStore;
    if (payload.variables.length) {
      await moduleStore.createVariable(appId, moduleId, {
        node_id: payload.nodeId,
        node_name: payload.nodeName,
        module_id: moduleId,
        variables: payload.variables
      });
    }
  };
  return {
    handleDelete,
    copyNode
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.node {
  &:hover {
    .node-options {
      .options-button {
        transform: scale(1);
      }
    }
  }
  .node-options {
    position: absolute;
    right: -0.5rem;
    top: -0.625rem;
    z-index: 10;
    .options-button {
      border-radius: 50%;
      transition: all 0.1s ease-in-out;
      height: 2rem;
      width: 2rem;
      transform: scale(0);
      &.visible {
        transform: scale(1) !important;
      }
      i {
        &:before {
          font-size: 1rem !important;
        }
      }
    }
  }
}
</style>
